import React from 'react';
import { CodeBlock } from '@atlaskit/code';

export default function CSSCodeBlock() {
    
    const codeBlock = `.wpshortcode-posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 18px;
}

.wpshortcode-posts-grid .post-item {
    background: #fff;
    box-shadow: 0 0 16px #00000017;
    color: #363636;
    max-width: 310px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
}

.wpshortcode-posts-grid .post-thumbnail-wrapper {
    height: 130px;
    overflow: hidden;
}

.wpshortcode-posts-grid .post-thumbnail-wrapper img {
    object-fit: cover;
    width: 100%;
}

.wpshortcode-posts-grid .post-content-wrapper {
    padding: 20px;
}

.wpshortcode-posts-grid .post-item .post-title {
    margin: 0 0 5px 0;
    font-size: 1.3em;
}

.wpshortcode-posts-grid .post-author {
    font-style: italic;
    font-size: 15px;
    color: #2a71c2;
    margin-top: 5px;
}

.wpshortcode-posts-grid .post-excerpt {
    margin-bottom: 0;
}

.wpshortcode-posts-grid .post-item .post-date {
    color: #a7a7a7;
    font-weight: bold;
    font-size: 13px;
}

.wpshortcode-posts-grid .post-footer {
    text-align: right;
    padding: 10px 20px;
}

.wpshortcode-posts-grid .post-footer .post-readmore {
    font-size: 14px;
    color: #2a71c2;
    text-decoration: none;
    font-weight: 500;
}

.wpshortcode-posts-grid .post-floating-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}`;

  return (
    <CodeBlock language="css" showLineNumbers={false} text={codeBlock} />
  );
};