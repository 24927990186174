import Popup from 'reactjs-popup';
import { Button } from '@mantine/core';
import 'reactjs-popup/dist/index.css';

export default function AboutPopup() {
      
    return(
        <Popup className='about-modal' modal trigger={<Button className='about-btn'>About</Button>}>
            <div className="about">
                <h3>Developed by Willon Nava</h3>
                dev.willnv@gmail.com
            </div>
        </Popup> 
    );
}