import thumbnailImg from '../img/panda.jpg'
import Chance from 'chance';
import { generate } from "random-words";

export default function PostItem({options}) {
    
    var chance = new Chance();
    
    return(
        <div className="post-item">
            {
                options.showThumbnail ?
                <div className="post-thumbnail-wrapper">
                    <img src={thumbnailImg} alt="Post Thumbnail"/>
                </div> :''
            }
            <div className="post-content-wrapper">
                <h2 className="post-title">
                    {generate({ exactly: 1, wordsPerString: 3, formatter: (word) => {
                        return word.slice(0, 1).toUpperCase().concat(word.slice(1))
                    }, })}
                </h2>
                {
                    options.showDate ?
                    <div className="post-date">{chance.date({string: true, year: 2023})}</div> 
                    :''
                }
                {
                    options.showExcerpt ?
                    <p className="post-excerpt">{chance.paragraph({ sentences: 1 })}</p>
                    :''
                }
                {
                    options.showAuthor ?
                    <div className="post-author">- by {chance.name()}</div> 
                    : ''
                }
            </div>
            <div className="post-footer">
                {
                    options.showReadMore ?
                    <a href="#1" className="post-readmore">Read More</a> 
                    : ''
                }
            </div>
            {
                options.addLinkFullCard ?
                <a href="#1" aria-label="post link" className="post-floating-link">&nbsp;</a> 
                : ''
            }
        </div>
    );
}