import Popup from 'reactjs-popup';
import {Button, CopyButton} from '@mantine/core';
import 'reactjs-popup/dist/index.css';
import PHPCodeBlock from './PHPCodeBlock';
import CSSCodeBlock from './CSSCodeBlock';
import { IconCopy } from '@tabler/icons-react';
import { Tabs } from '@mantine/core';

export default function ShortcodePopup({shortcodeOptions}) {
      
    return(
        <Popup className='modal shortcode-modal' modal trigger={<Button className='generate-btn'>Generate Shortcode</Button>} position="right center">
            <div>
                <div className="popup-header">
                    <h4>{shortcodeOptions.name.pretty}</h4>
                </div>
                
                <div className="shortcode-code">
                    <span>[{shortcodeOptions.name.label}]</span>
                    <CopyButton value={1}>
                    {({ copied, copy }) => (
                        <Button className="copybtn" leftIcon={<IconCopy/>} color={copied ? 'teal' : 'blue'} onClick={copy}></Button>
                    )}
                    </CopyButton>
                </div>
                
                <Tabs defaultValue="php">
                    <Tabs.List>
                        <Tabs.Tab value="php">PHP</Tabs.Tab>
                        <Tabs.Tab value="css">CSS</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="php" pt="xs">
                        <p className="tip">- Paste this code at the end of your theme's <em>functions.php</em> file.</p>
                        <p className="tip">- If your <em>functions.php</em> file doesn't have a PHP closing tag at the end {`(?>)`}, please remove the PHP opening tag {`(<?php)`} from your copied code. </p>
                        <div className="code-wrapper">
                            <PHPCodeBlock shortcodeOptions={shortcodeOptions}/>
                            <CopyButton value={1}>
                            {({ copied, copy }) => (
                                <Button className="copybtn" leftIcon={<IconCopy/>} color={copied ? 'teal' : 'blue'} onClick={copy}></Button>
                            )}
                            </CopyButton>
                        </div>
                    </Tabs.Panel>

                    <Tabs.Panel value="css" pt="xs">
                        <div className="code-wrapper">
                            <CSSCodeBlock shortcodeOptions={shortcodeOptions}/>
                            <CopyButton value={1}>
                            {({ copied, copy }) => (
                                <Button className="copybtn" leftIcon={<IconCopy/>} color={copied ? 'teal' : 'blue'} onClick={copy}></Button>
                            )}
                            </CopyButton>
                        </div>
                    </Tabs.Panel>
                </Tabs>
                
            </div>
        </Popup> 
    );
}