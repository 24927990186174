import React from 'react';
import { CodeBlock } from '@atlaskit/code';

export default function PHPCodeBlock({shortcodeOptions}) {
    
    const getPostCount = () => shortcodeOptions.postCount;
    const getPostTypes = () => shortcodeOptions.postTypes;
    
    const getShortcodeName = (type) => {
        
        let name = shortcodeOptions.name.pretty;
        
        if (type === 'function') {
            name = shortcodeOptions.name.function;
        } else if (type === 'label') {
            name = shortcodeOptions.name.label;
        }
            
        return name;
    }
    
    const maybeShowAuthor = () => {
        return shortcodeOptions.showAuthor ?
        '\n'+
        '                    <div class="post-author">- by <?php echo get_the_author(); ?></div>' :
        '';
    }
    
    const maybeShowDate = () => {
        return shortcodeOptions.showDate ?
        '\n'+
        '                    <div class="post-date"><?php echo get_the_date(); ?></div>' :
        '';
    }
    
    const maybeShowExcerpt = () => {
        return shortcodeOptions.showExcerpt ?
        '\n'+
        '                    <p class="post-excerpt"><?php echo get_the_excerpt(); ?></p>' :
        '';
    }
    
    const maybeShowReadMore = () => {
        return shortcodeOptions.showReadMore ?
        '\n'+
        '                    <a href="<?php echo get_the_permalink(); ?>" class="post-readmore">Read More</a>' :
        '';
    }
    
    const maybeShowLinkFullCard = () => {
        return shortcodeOptions.addLinkFullCard ?
        '\n'+
        '                <a href="#1" aria-label="post link" class="post-floating-link"> </a>' :
        '';
    }
    
    const codeBlock = `<?php function ${getShortcodeName('function')}() {
                        
    $query = WP_Query(array(
        'post_type' => [${getPostTypes()}],
        'post_count' => ${getPostCount()},
    ));
            
    ob_start();
    
    if ( $query->have_posts() ): while ( $query->have_posts() ): $query->the_post(); ?>
        <div class="wpshortcode-posts-grid">
            <div class="post-item">
                <div class="post-thumbnail-wrapper">
                    <img src="<?php echo get_the_post_thumbnail_url(); ?>" alt="Post thumbnail" />
                </div>
                
                <div class="post-content-wrapper">
                    <h2 class="post-title"><?php echo get_post_title(); ?></h2>${maybeShowAuthor()}${maybeShowExcerpt()}${maybeShowDate()}
                </div>
                
                <div class="post-footer">${maybeShowReadMore()}
                </div>
                ${maybeShowLinkFullCard()}
            </div>
        </div>
    <?php endwhile; else : ?>
        <p><?php esc_html_e( 'Sorry, no posts matched your criteria.' ); ?></p>
    <?php endif; 
    
    wp_reset_postdata(); ?>
        
    <?php return ob_get_clean();
}
add_action('${getShortcodeName('label')}', '${getShortcodeName('function')}');`;

  return (
    <CodeBlock language="php" showLineNumbers={false} text={codeBlock} />
  );
};