import './App.css';
import { useEffect, useState } from 'react';
import { Checkbox, TextInput, MultiSelect, NumberInput } from '@mantine/core';
import PostItem from './components/PostItem';
import AboutPopup from './components/AboutPopup';
import ShortcodePopup from './components/ShortcodePopup';

function App() {
  
  const [options, setOptions] = useState({
    name: {
      pretty: 'My Post Loop', 
      function: 'my_post_loop', 
      label:'my-post-loop'
    },
    showDate: true,
    showAuthor: false,
    showExcerpt: true,
    showThumbnail: true,
    showReadMore: true,
    addLinkFullCard: false,
    postCount: 6,
    postTypes: ['post'],
  });
  
  useEffect(() => {
    console.log(options.postTypes);
  });
  
  const handleNameChange = (e) => {
    let newName = e.currentTarget.value;
    
    setOptions({
      ...options,
      name: {
        pretty: newName,
        function: newName.toLowerCase().replace(/\s/g, "_"),
        label: newName.toLowerCase().replace(/\s/g, "-"),
      }
    });
  }
  
  const handleOptionChange = (e) => {
  
    let value;
    
    // if post count
    if (typeof e == 'number') {
      setOptions({
        ...options,
        postCount: e
      });
    // if check fields
    } else {
      value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      
      setOptions({
        ...options,
        [e.target.name]: value
      });
    }
  }
  
  const handleMultiselectChange = (query) => {
    
    let updatedArray = [...options.postTypes, query];
    
    setOptions({
      ...options,
      postTypes: updatedArray
    });
  }
  
  return (
    <div className="App">
      <div className="col-left">
        <aside className="options-bar">
          <div className="col-header">
              <h2>Post Type Loop</h2>
              <h1>Shortcode Generator</h1>
          </div>
          <TextInput
            className="option border"
            name="name"
            defaultValue={options.name.pretty}
            placeholder="Unique Name"
            label="Unique Name"
            required
            onChange={handleNameChange}
          />
          <Checkbox
            className="option"
            id="showThumbnail"
            name="showThumbnail"
            label="Show Thumbnail"
            checked={options.showThumbnail}
            onChange={handleOptionChange}
          />
          <Checkbox
            className="option"
            id="showDate"
            name="showDate"
            label="Show Date"
            checked={options.showDate}
            onChange={handleOptionChange}
          />
          <Checkbox
            className="option"
            id="showAuthor"
            name="showAuthor"
            label="Show Author"
            checked={options.showAuthor}
            onChange={handleOptionChange}
          />
          <Checkbox
            className="option"
            id="showExcerpt"
            name="showExcerpt"
            label="Show Excerpt"
            checked={options.showExcerpt}
            onChange={handleOptionChange}
          />
          <Checkbox
            className="option"
            id="showReadMore"
            name="showReadMore"
            label='Show "Read More"'
            checked={options.showReadMore}
            onChange={handleOptionChange}
          />
          <Checkbox
            className="option border"
            id="addLinkFullCard"
            name="addLinkFullCard"
            label="Link covers full card"
            checked={options.addLinkFullCard}
            onChange={handleOptionChange}
          />
          <NumberInput
            className="option"
            label="Post count"
            name="postCount"
            defaultValue={options.postCount}
            description="before pagination starts"
            max={24}
            min={1}
            onChange={handleOptionChange}
          />
          <MultiSelect
            className="option"
            label="Post Types"
            data={options.postTypes}
            placeholder="Select items"
            creatable
            searchable
            defaultValue={['post']}
            getCreateLabel={(query) => `+ add ${query}`}
            onCreate={(query) => {
              handleMultiselectChange(query);
              return query;
            }}
          />
          <ShortcodePopup shortcodeOptions={options}/>
        </aside>
        <div className="credits">
          <AboutPopup/>
        </div>
      </div>
      
      <div className="col-right">
        <div className="preview-wrapper">
          <div className="posts-grid">
            {
            (() => {
              let rows = [];
              for (let i = 0; i < options.postCount; i++) {
                rows.push(<PostItem key={i} options={options}/>);
              }
              return rows;
            })()
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
